body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, body {
  font-size: 12px;
}
.app-main{
  // 自定义table样式
  .ant-table-thead > tr > th{
    background-color: #025f9c;
    color: #fff;
  }
  .ant-table-tbody > tr > td{
    background-color: #084a75;
    color: #fff;
  }
  .ant-empty-normal{
    color: #fff;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover{
    background-color: #0a75bb;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover{
    background-color: #0a75bb;
  }
  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder{
    background-color: #0a75bb;
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td{
    background-color: #0a75bb;
  }
}
// 图表弹窗
.cs-main{
  padding: 12px;
  .cs-title{
    margin-bottom: 12px;
  }
  .cs-value{
    margin-bottom: 12px;
  }
  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    li{
      line-height: 24px;
    }
    .cs-marker{
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 8px;
    }
  }
}

// 移动端
@media screen and (max-width: 500){
  .ant-picker-panel-container .ant-picker-panels{
    flex-direction: column;
  }
}