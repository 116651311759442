.header{
  width: 100%;
  padding: 0 24px 0 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  .header-control {
    display: flex;
    justify-content: space-between;
    .clear, .help, .shop{
      margin-right: 15px;
      color: #fff;
      i{
        display: inline-block;
        width: 25px;
        height: 25px;
        vertical-align: middle;
      }
    }
    .logout{
      font-size: 2.5rem;
      color: #fff;
    }
    .avatar{
      margin-right: 25px;
    }
  }
  .user-center{
    color: #fff;
  }
  .header-title{
    font-size: 1.6rem;
    color: #fff;
    margin: 0;
    margin-left: 1.2rem;
  }
  .menu-control{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
}