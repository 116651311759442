.shop-main{
  padding: 2.4rem;
  .shop-search {
    margin-bottom: 2.4rem;
  }
  .shop-table{
    padding: 2.4rem;
    background-color: #0d3b5a;
    width: 100%;
    overflow: auto;
    .ant-table{
    }
  }
}