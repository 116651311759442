.all-products {
  padding: 24px;
  .product-panel {
    width: 100%;
    box-shadow: 1px 2px 3px rgb(134, 132, 132);
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 35px;
    box-sizing: border-box;
    .top{
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .title{
        font-size: 24px;
        font-weight: bold;
      }
      .btn-check{
        font-size: 16px;
        color: #3376e6;
      }
    }
    .info {
      height: 85px;
      p{
        margin: 0;
      }
      .font-weight {
        line-height: 1.5;
        font-size: 26px;
        font-weight: bold;
      }
      .font-normal{
        line-height: 1.5;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .chart-tip{
    font-size: 12px;
  }
  .list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 1.5;
    span{
      display: inline-block;
    }
    .list-it1{
      width: 50%;
    }
    .list-it2{
      width: 30%;
    }
    .list-it3{
      width: 20%;
    }
  }
}