.trend-main {
  padding: 2.4rem;
  .ant-table{
    min-width: 600px;
  }
  .trend-chart {
    margin-top: 1.2rem;
    .trend-chart-main{
      >div{
        height: 100%;
      }
      height: 30rem;
      background-color: #0d3b5a;
      padding: 2rem;
      box-sizing: border-box;
      border: 1px solid #105583;
    }
  }
  .trend-title {
    color: #fff;
    text-align: center;
    font-size: 2rem;
  }
  .trend-table-content {
    background-color: #0d3b5a;
    padding: 2rem;
    border: 1px solid #105583;
    width: 100%;
    overflow: auto;
  }
}

.ant-table tfoot>tr>td, .ant-table tfoot>tr>th, .ant-table-tbody>tr>td, .ant-table-thead>tr>th{
  padding: 8px;
}

.ant-table-cell p{
  margin: 0px;
  padding: 0px;
}


.ant-table-cell p.ratio{
  color: #888;
}

