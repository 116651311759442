.app-main{
  height: 100%;
  .logo{
    width: 100%;
    img{
      width: 100%;
    }
  }
  .mobile-title{
    color: #fff;
  }
}
.content-main {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #102135;
}
.app-mobile-menu{
  .ant-drawer-header-title{
    flex-direction: row-reverse;
    .ant-drawer-close{
      margin-right: 0;
      color: #fff;
    }
  }
  .mobile-title{
    color: #fff;
  }
  .mobile-menu{
    height: 100%;
    position: relative;
    .btn-logout {
      position: absolute;
      width: 100%;
      bottom: 0;
      border: none;
      background-color: #102135;
      color: #fff;
    }
  }
}