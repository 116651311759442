.monitor{
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  .header-item{
    border-radius: 0.2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    color: #fff;
    font-size: 0.5rem;
    &.header-c1{
      background-image: linear-gradient(to bottom left, #34b6e1, #5bc4bc);
    }
    &.header-c2{
      background-image: linear-gradient(to bottom left, #09bdfe, #007fb5);
    }
    &.header-c3{
      background-image: linear-gradient(to bottom left, #b6910c, #e69110);
    }
    &.header-c4{
      background-image: linear-gradient(to bottom left, #ce593e, #ec808c);
    }
    &.header-c5{
      background-image: linear-gradient(to bottom left, #7b7ada, #bf81ff);
    }
    p{
      margin: 0;
      font-size: 1.4rem;
    }
    .mainTxt{
      font-weight: bold;
    }
    .info{
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      .info-list{
        text-align: left;
        width: 100%;
        p{
          font-size: 1.2rem;
        }
      }
      .icon{
        width: 40%;
        img{
          width: 100%;
        }
      }
    }
  }
}
.monitor-center{
  margin-top: 1rem;
  overflow: hidden;
  .chart-main{
    background-color: #0d3b5a;
    padding: 1rem;
    color: #fff;
    height: 100%;
    box-sizing: border-box;
    border-radius: 9px;
  }
  .chart-title{
    padding-left: 1rem;
    position: relative;
    font-size: 1.6rem;
    .mini-tit{
      font-size: 1.2rem;
    }
  }
  .chart-title::after{
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    width: 3px;
    height: 10px;
    transform: translate(0, -50%);
    background-color: #01a7f0;
  }
  .chart-content {
    width: 100%;
    height: 300px;
    margin: 0 auto;
  }
  .chart-content1 {
    width: 100%;
    height: 300px;
    margin: 0 auto;
  }
  .mini-tit{
    font-size: 0.1rem;
  }
  .visitor{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .visitor-item{
      width: 25%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .icon{
        width: 100%;
        padding: 40% 30%;
        box-sizing: border-box;
        img{
          width: 100%;
        }
      }
      p{
        text-align: center;
        margin: 0;
        line-height: 1.5;
        color: #5f808f;
        font-size: 2rem;
      }
    }
  }
}