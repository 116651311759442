.left-main{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #03375f;
  .ant-menu{
    background-color: #03375f;
    color: #ffffff;
  }
  .menu-btns{
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    .btn{
      color: #000;
    }
  }
  .logo{
    height: 60px;
    font-size: 2rem;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }
}