.login-main{
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #03375f;
  padding: 0;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  .login-form{
    width: 45rem;
    height: auto;
    padding: 4rem;
    background-color: #fff;
  }
  .login-img{
    margin-left: -8px;
    max-width: 21rem;
    font-size: 0;
  }
  h1{
    margin: 4.5rem 0 0 0;
    font-size: 2.9rem;
    font-weight: 400;
    padding: 0;
    line-height: 1;
  }
  .tips{
    margin: 24px 0 0;
    color: #484949;
    font-size: 18px;
  }
  .main-form{
    margin-top: 39px;
    .ant-input-affix-wrapper{
      border: 1px solid #e5e5e5;
    }
    .ant-input{
      height: 4rem;
      font-size: 1.4rem;
    }
    .ant-input-prefix{
      width: 3.5rem;
      text-align: center;
    }
    .input-icon{
      font-size: 2.8rem;
      color: #a2a2a2;
    }
  }
  .btn-login {
    width: 18rem;
    height: 4rem;
    line-height: 1;
    font-size: 1.4rem;
    background-color: #03375f;
    color: #fff;
    border: none;
    cursor: pointer;
  }
}